.general-single-horizontal-tab {
  font-size: 1.2em;
  font-weight: 500;
  margin-right: 30px;
}

.single-horizontal-tab-active {
  border-bottom: 2px solid #111;
  color: #111;
}

.single-horizontal-tab-active:hover {
  border-bottom: 2px solid #000;
  color: #000;
  text-decoration: none;
}
