.horizontal-cell-padding {
  padding-left: 0;
  padding-right: 10px;
}

.horizontal-cell-padding:first-child {
  padding-left: 20px;
}

.horizontal-cell-padding:last-child {
  padding-right: 20px;
}

.basic-table-parent { 
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;

  thead{
    margin-top: 45px;
    tr{
      vertical-align: bottom;
      th{
        div{
          justify-content: center;
        }
      }
    }
  }

  tbody {
    tr {
      background-color: #fff;
      cursor: pointer;
    }

    tr:hover {
      background-color: #eee;
    }
  }

  td {
    padding-top: 17px;
    padding-bottom: 15px;
    padding-right: 5px;
    padding-left: 8px;
  }

  th{
    padding-left: 8px;
  }

  td:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    padding-left: 20px;
  }

  td:last-child { 
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    padding-right: 20px;
  }
}

.comparison-row {
  border-bottom: solid 1px $light-gray;
}

.comparison-row:last-child {
  border-bottom: none;
}

.comparison-row > td {
  padding-top: 13px;
  padding-bottom: 13px;
}