.calloutMetricArrowBox__comparisonPeriod--positive {
    color: green
}

.calloutMetricArrowBox__comparisonPeriod--negative {
    color: red
}

.calloutMetricArrowBox__box {
    width: 6rem;
    height: 7rem;
    font-size: large;
}
