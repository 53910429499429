@import './variables.scss';
@import "node_modules/bootstrap/scss/bootstrap";
@import "./font.scss";
@import "./text.scss";
@import "./basicTable.scss";
@import './custom.scss';
@import './responsive.scss';

body {
  background-color: #f4f4f7;
  color: #1a1a1a;
}

.border-white-transparent {
  border: 1px solid rgba(255,255,255,0.25);
}

.border-light-grey {
  border-color: #dfdfdf;
}

.border-rd-2 {
  border-radius: 2px;
}

.border-rd-3 {
  border-radius: 3px;
}

.border-2 {
  border-width: 2px; 
  border-style: solid;
}

*:focus {
  outline: none !important;
}

.outline:focus {
  box-shadow: 0 0 0 4px rgba(41, 120, 194, 0.75);
}

.using-mouse .outline:focus {
  box-shadow: none;
}

.fit-content {
  width: fit-content;
}

.center-children {
  display: flex;
  justify-content: center;
  align-items: center;
}

.low-contrast {
  filter: contrast(0.5);
}

.low-brightness {
  filter: brightness(0.6);
}

.pointer {
  cursor: pointer;
}

.low-opacity-primary {
  background-color: rgba(41, 120, 194, 0.1);
}

.low-opacity-primary-hover:not(.bg-primary):hover {
  background-color: rgba(41, 120, 194, 0.1) !important;
  color: #1a1a1a;
}

.rotate-180 {
  transform: rotateZ(180deg);
}

.font-offset {
  position: relative;
  top: 1px;
  left: 0.5px
}

.text-primary-dark-3 {
  color: $primary-dark-3;
}

.no-bg {
  background: none !important;
}

.flex-g-1 {
  flex-grow: 1;
}

.no-underline, .no-underline:hover, .no-underline:active {
  text-decoration: none;
}

.pos-r-0 {
  right: 0;
}

.pos-t-0 {
  top: 0;
}

.mr-16 {
  margin-right: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.basic-shadow {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.04);
}

.high-blur-shadow {
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
}

.logo {
  height: 33px;
  width: 33px;
}

.underline-hover:hover {
  text-decoration: underline;
}
