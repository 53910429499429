.erroBoundaryContainer{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 10rem;
}

.logoContainer{
    width: 13rem;
}