@font-face {
  font-family: 'Avenir-Black';
  src: url('../assets/fonts/AvenirBlack.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Heavy';
  src: url('../assets/fonts/AvenirHeavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('../assets/fonts/AvenirMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Light';
  src: url('../assets/fonts/AvenirLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}