.periodSelector__row {
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.periodSelector--picker {
    margin: 8px;
    min-width: 300px;
}

input#periodSelectorCheckbox {
    margin-left: 10px;
}