.datePicker__overview {
    width: 230px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid;
    border-bottom-color: lightgray;
    padding: 3px;
}

.datePicker__overview:hover {
    cursor: pointer;
    background-color: rgb(231, 231, 231);
}
