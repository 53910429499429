.signIn {
  height: 100vh;
  color: #1E2F43;
}

.signIn__beaconLogo {
  width: 150px;
  height: 150px;
}

.signIn__button {
  background-color: #1E2F43;
  border-color: #1E2F43;
  padding: .5rem 1rem;
  color: #FFF;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}
