* {
    box-sizing: border-box;
}

.main > div {
    margin: 8px;
}

.metrics {
    margin-right: 5%;
    margin-left: 5%;
}

.container.graph .row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
}
.recharts-responsive-container {
    margin-bottom: 5%;
}