.general-horizontal-pill-tab {
  border: 1px #bbb solid;
  color: #888;
  font-weight: 700;
  font-size: 0.9em;
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 30px;
}

.horizontal-pill-tab-active {
  background-color: #bbb;
  color: #111;
}

.horizontal-pill-tab-active:hover {
  color: #000;
  text-decoration: none;
}
