.input-md {
  width: 50vw;
  max-width: 386px;
  padding: 11px 9px 7px 35px;
  caret-color: var(--primary);
}

.input-md:hover:not(:focus) {
  background-color: #f3f3f3;
  border-color: #bcbbbb;
}

.input-md:focus {
  border-color: #888383;
}

.sm-pill {
  height: 32px;
  border-radius: 16px;
}

.small-square {
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  color: #7e8488;
  margin-left: 4.5px;
  margin-right: 4.5px;
  border-radius: 3px;
}

.sm-icon {
  max-height: 15px;
  max-width: 15px;
  margin: 10px;
}

.custom-suggestion-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 999;
  border: solid 2px #dfdfdf;
  border-top: none !important;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 11px 10px;
  max-height: 600px;
  overflow-y: auto;
}

.beac-button {
  min-width: 102px;
  width: fit-content;
  padding-top: 10px;
  padding-bottom: 8px;
}

.beac-button-primary {
  background-color: var(--primary);
  color: white;
  border: none;
}

.beac-button-primary:hover:not(:active) {
  background-color: $primary-dark-1;
}

.beac-button-primary:active {
  background-color: $primary-dark-2;
}

.beac-button-secondary {
  background: none;
  color: var(--secondary);
  border: 2px #dfdfdf solid;
}

.beac-button-secondary:hover:not(:active) {
  background-color: rgba(223, 223, 223, 0.25);
  border-color: #bcbbbb;
}

.beac-button-secondary:active {
  border-color: #888383;
}

button.bg-primary:hover:disabled {
  background-color: var(--primary) !important;
}

.minw-100p {
  min-width: 100%;
}

.minw-120 {
  min-width: 120px
}

.minh-40 {
  min-height: 40px;
}

.p-dropdown {
  padding: 8px 16px 6px;
}

.sqr-22 {
  height: 22px;
  width: 22px;
}

.slide-down {
  top: 100%;
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.slide-up {
  bottom: 100%;
  border-bottom-width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.header-custom {
  height: 64px;
  background-color: $header-bg;
  padding-left: 51px;
  padding-right: 59px;
}

.picture-sm {
  height: 40px;
  width: 40px;
  overflow: hidden;
  padding: 0;
  border-radius: 20px;
  background: none;
  border: none;
}

.reference-point-bottom-center {
  padding: 0;
  height: 0;
  width: 0;
  border: none;
  position: absolute;
  top: 100%;
  cursor: default;
}

.sm-upwards-triangle {
  position: relative;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  z-index: 999;
}

.popup-menu {
  padding: 17px 24px 32px 28px;
  min-width: 200px;
  z-index: 998;
  top: 10px;
  right: -33px;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}