.rdrWeekDays {
  display: none;
}

.rdrDayPassive .rdrDayNumber span {
  color: transparent !important;
  background: none;
}
.rdrDayToday .rdrDayNumber span:after {
  background: none;
}

.rdrMonthName {
  font-family: Avenir;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1a1a1a;
}

.rdrMonthAndYearPickers {
  display: none;
}

.rdrCalendarWrapper {
  position: relative;
}

.rdrMonthAndYearWrapper {
  position: absolute;
}

.rdrMonthAndYearWrapper {
  height: 0;
  padding: 0;
}

/* Days and Ranges */
.rdrDay {
  background: transparent;
  z-index: 1;
}

.rdrInRange {
  background: #e6f1fb;
}

.rdrDay .rdrDayNumber span {
  color: #7e8488;
  font-size: 13px;
  font-weight: 700;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive),
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: #7e8488;
  font-size: 13px;
  font-weight: 700;
}

.rdrEndEdge ~ .rdrDayNumber span {
  color: transparent !important;
}

.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
  color: #fff !important;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span {
  color: #fff !important;
  font-size: 13px;
  font-weight: 700;
}

.rdrEndEdge,
.rdrStartEdge {
  background-color: #2978c2;
}

.rdrDayEndOfWeek .rdrStartEdge,
.rdrDayEndOfMonth .rdrStartEdge{
  border-radius: 1.042em !important;
} 

.rdrDayStartOfWeek .rdrEndEdge,
.rdrDayStartOfMonth .rdrEndEdge{
  border-radius: 1.042em !important;
} 

.rdrEndEdge:not(.rdrStartEdge) {
  border-radius: 0 1.042em 1.042em 0;
}
.rdrStartEdge:not(.rdrEndEdge){
  border-radius: 1.042em 0 0 1.042em;
}

.rdrDay .rdrEndEdge + .rdrDayNumber span,
.rdrDay .rdrStartEdge + .rdrDayNumber span {
  color: #fff !important;
}

.rdrDayHovered {
  border: none;
}

/* Button Prev - Next */

.rdrNextPrevButton i {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #1a1a1a;
  box-sizing: border-box;
}
.rdrNextPrevButton i:after,
.rdrNextPrevButton i:before {
  content: '';
  box-sizing: border-box;
}
.rdrNextPrevButton i {
  width: 8px;
  height: 8px;
  border-width: 2.5px 2.5px 0 0;
  border-style: solid;
}
.rdrNextPrevButton i:before {
  right: -4px;
  top: 3px;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  height: 2px;
  color: #1a1a1a;
  transform: rotate(-45deg);
  width: 14px;
  border-bottom: 2px solid #1a1a1a;
}

.rdrNextPrevButton {
  margin: 0;
  background: transparent;
}
.rdrNextPrevButton:hover {
  background: transparent;
}
/* Prev Button */

.rdrPprevButton {
  transform: rotate(-135deg);
  margin: 43px 0px 0px 15px;
}
.rdrPprevButton i {
  border-color: #1a1a1a #1a1a1a transparent transparent;
}

.rdrPprevButton i:before {
  right: -3px;
  top: 3px;
}

/* Next Button */

.rdrNextButton {
  transform: rotate(45deg);
  margin: 36px 19px 0px 0px;
}
.rdrNextButton i {
  border-color: #1a1a1a #1a1a1a transparent transparent;
}

/* Arrows wrapper */

.rdrMonthAndYearWrapper {
  width: 100%;
  position: absolute;
}

/* Selected Edges */

.rdrDayEndOfMonth:not(.rdrDayEndOfWeek) .rdrStartEdge:after,
.rdrDayEndOfMonth:not(.rdrDayEndOfWeek) .rdrInRange:after {
  content: '';
  background: linear-gradient(
    90deg,
    transparent 0%,
    #e6f1fb 40%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 200%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.rdrDayStartOfMonth:not(.rdrDayStartOfWeek) .rdrEndEdge:after,
.rdrDayStartOfMonth:not(.rdrDayStartOfWeek) .rdrInRange:after {
  content: '';
  background: linear-gradient(
    -90deg,
    transparent 0%,
    #e6f1fb 40%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 200%;
  height: 100%;
  position: absolute;
  left: -100%;
  top: 0;
  z-index: -1;
}
