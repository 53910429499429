.calloutMetricArrowBox__comparisonPeriod {
    display: flex;
    justify-content: center;
    font-size: large;
    padding: 5%;
}

.calloutMetricArrowBox__p {
    font-size: small;
}

.calloutMetric {
    margin-bottom: 15px !important;
}

.calloutMetric .card {
    border: none;
}
.calloutMetric.border-dark.card {
    max-width: 230px;
    min-width: 230px;
}
.calloutMetric .col.callout {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10%;
}
