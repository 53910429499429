@media (max-width: 700px) {
  .mobile-flex-vert {
    flex-direction: column;
  }

  .mobile-not-absolute {
    position:static !important;
  }

  .mobile-spacing-top {
    margin-top: 24px;
  }

  .header-custom {
    padding-left: 16px;
    padding-right: 16px;
  }
}