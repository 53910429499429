// FONT SIZING

.txt-sz-xs {
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
}
.txt-sz-s {
  font-size: 13px;
  line-height: 1;
}
.txt-sz-m {
  font-size: 15px;
  line-height: 22px;
}

.txt-sz-l {
  font-size: 18px;
  line-height: 22px;
}
.txt-sz-xxl {
  font-size: 48px;
  line-height: 1;
}

.txt-sz-16 {
  font-size: 16px;
}


// FONT FAMILY

.avenir-heavy {
  font-family: 'Avenir-Heavy', sans-serif;
}
.avenir-medium {
  font-family: 'Avenir-Medium', sans-serif;
}
.avenir-light {
  font-family: 'Avenir-Light', sans-serif;
}
.avenir-black {
  font-family: 'Avenir-Black', sans-serif;
}
